import React, { useState } from "react";
import PropTypes from "prop-types";

function HamburgerMenu({ onToggle, isOpen: initialIsOpen = false }) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const toggleMenu = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (onToggle) onToggle(newState);
  };

  return (
    <button
      className={`hamburger flex flex-col justify-between items-center w-8 h-8 p-1 ${
        isOpen ? "open" : ""
      }`}
      onClick={toggleMenu}
      aria-label="Toggle navigation"
      aria-expanded={isOpen}
    >
      <div
        className={`w-full h-1 bg-gray-800 rounded transition-transform duration-300 ${
          isOpen ? "transform rotate-45 translate-y-[7px]" : ""
        }`}
      ></div>
      <div
        className={`w-full h-1 bg-gray-800 rounded transition-opacity duration-300 ${
          isOpen ? "opacity-0" : ""
        }`}
      ></div>
      <div
        className={`w-full h-1 bg-gray-800 rounded transition-transform duration-300 ${
          isOpen ? "transform -rotate-45 -translate-y-[7px]" : ""
        }`}
      ></div>
    </button>
  );
}

HamburgerMenu.propTypes = {
  onToggle: PropTypes.func, // Callback for when the menu is toggled
  isOpen: PropTypes.bool,   // Initial state of the menu
};

export default HamburgerMenu;
