import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../Toast";
import ActionButton from "../Buttons/ActionButton";

// Import the helper
import { saveRecipe } from "../../utils/helpers.ts"; // adjust path as needed

function RecipeResult({ recipe, user, onReset }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [toast, setToast] = useState({ message: "", type: "" });

  const showToast = (message, type = "info") => {
    setToast({ message, type });
    setTimeout(() => {
      setToast({ message: "", type: "" });
    }, 3000);
  };


  // Decode HTML entities from fields
  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  // If you get a single instructions string with "1. ", "2. ", split them
  const formatInstructions = (instructions) => {
    if (instructions.length === 1) {
      const singleInstruction = instructions[0];
      const stepRegex = /\d+\.\s+/g; // e.g. "1. " or "2. "
      if (stepRegex.test(singleInstruction)) {
        return singleInstruction.split(stepRegex).filter(Boolean);
      }
    }
    return instructions;
  };

  // Safely decode the incoming recipe object
  const decodedRecipe = {
    ...recipe,
    name: recipe?.name ? decodeHTML(recipe.name) : "Untitled Recipe",
    ingredients: recipe?.ingredients
      ? recipe.ingredients.map((ing) => decodeHTML(ing))
      : [],
    instructions: recipe?.instructions
      ? formatInstructions(recipe.instructions.map((inst) => decodeHTML(inst)))
      : [],
  };

  // ====== EDIT STATE ======
  // 1) editing = whether the form fields are editable or read-only
  const [editing, setEditing] = useState(false);

  // 2) editableRecipe = local state for the fields being edited
  //    Initialize once with `decodedRecipe`.
  const [editableRecipe, setEditableRecipe] = useState(decodedRecipe);

  // If you fetch a brand-new recipe (with a different ID), update local editable state
  useEffect(() => {
    if (recipe?.id) {
      setEditableRecipe(decodedRecipe);
    }
    // eslint-disable-next-line
  }, [recipe?.id]);

  // ========== Save to Recipe Box ==========
  const handleSave = useCallback(async () => {
    if (!user) {
      showToast("You need to be logged in to save recipes.", "error");
      navigate("/account");
      return;
    }

    try {
      // Use the updated fields (editableRecipe) rather than the original
      const data = await saveRecipe(user.id, editableRecipe, "General");
      if (data.error) {
        showToast(`Failed to save recipe: ${data.error}`, "error");
      } else {
        showToast("Recipe saved successfully!", "success");
        navigate("/recipe-box");
      }
    } catch (err) {
      showToast(`Failed to save recipe: ${err.message}`, "error");
    }
  }, [editableRecipe, user, navigate]);

  // ========== Print ==========
  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  // If no recipe is provided, redirect home
  useEffect(() => {
    if (!recipe) {
      navigate("/");
    }
  }, [recipe, navigate]);

  if (!recipe) {
    return null;
  }

  // ========== Handlers for Local Editing ==========
  const handleNameChange = (e) => {
    setEditableRecipe((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleUrlChange = (e) => {
    setEditableRecipe((prev) => ({ ...prev, url: e.target.value }));
  };

  const handleIngredientChange = (index, value) => {
    setEditableRecipe((prev) => {
      const updatedIngredients = [...prev.ingredients];
      updatedIngredients[index] = value;
      return { ...prev, ingredients: updatedIngredients };
    });
  };

  const handleInstructionChange = (index, value) => {
    setEditableRecipe((prev) => {
      const updatedInstructions = [...prev.instructions];
      updatedInstructions[index] = value;
      return { ...prev, instructions: updatedInstructions };
    });
  };

  // ========== Render ==========
  return (
    <div className="recipe-result max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
      <header className="result-header text-right">
        {editing ? (
          // Editable URL
          <input
            type="text"
            value={editableRecipe.url || ""}
            onChange={handleUrlChange}
            className="w-full max-w-md border border-gray-300 rounded px-2 py-1 mb-2"
            placeholder="Recipe URL"
          />
        ) : (
          // Read-only URL
          <h4 className="recipe-result-link">
            <a
              href={decodedRecipe.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Recipe from: {decodedRecipe.url}
            </a>
          </h4>
        )}
      </header>

      {/* Recipe Image (read-only, even in edit mode) */}
      {decodedRecipe.image && (
        <img
          src={decodedRecipe.image}
          alt={decodedRecipe.name || "Recipe Image"}
          className="recipe-image w-full h-auto rounded-lg mb-4"
          onError={(e) => {
            if (!e.target.dataset.fallback) {
              e.target.src = "/sample-image.jpg";
              e.target.dataset.fallback = true;
            }
          }}
        />
      )}

      {/* Recipe Name */}
      {editing ? (
        <input
          type="text"
          value={editableRecipe.name}
          onChange={handleNameChange}
          className="w-full text-2xl font-bold border border-purple-300 rounded px-2 py-1 mb-4"
          placeholder="Recipe Name"
        />
      ) : (
        <h2 className="recipe-title text-2xl font-bold text-purple-700 mb-4">
          {decodedRecipe.name}
        </h2>
      )}

      {/* Ingredients */}
      {editableRecipe.ingredients && editableRecipe.ingredients.length > 0 ? (
        <div className="ingredients-section mb-4">
          <h3 className="text-lg font-semibold text-pink-600 mb-2">
            Ingredients
          </h3>
          <ul className="list-disc list-inside">
            {editing
              ? // Editable mode: textareas
                editableRecipe.ingredients.map((ing, idx) => (
                  <li key={idx} className="mb-1">
                    <textarea
                      value={ing}
                      onChange={(e) =>
                        handleIngredientChange(idx, e.target.value)
                      }
                      className="w-full border border-gray-300 rounded px-2 py-1"
                    />
                  </li>
                ))
              : // Read-only mode: checkbox list
                editableRecipe.ingredients.map((ing, idx) => (
                  <li key={idx} className="text-gray-800">
                    <label>
                      <input
                        type="checkbox"
                        className="mr-2 rounded text-pink-500 focus:ring-pink-500"
                      />
                      {ing}
                    </label>
                  </li>
                ))}
          </ul>
        </div>
      ) : (
        <p className="text-gray-600">No ingredients provided.</p>
      )}

      {/* Instructions */}
      {editableRecipe.instructions && editableRecipe.instructions.length > 0 ? (
        <div className="instructions-section mb-4">
          <h3 className="text-lg font-semibold text-pink-600 mb-2">
            Instructions
          </h3>
          <ol className="list-decimal list-inside">
            {editing
              ? editableRecipe.instructions.map((step, idx) => (
                  <li key={idx} className="mb-2">
                    <textarea
                      value={step}
                      onChange={(e) =>
                        handleInstructionChange(idx, e.target.value)
                      }
                      className="w-full border border-gray-300 rounded px-2 py-1"
                    />
                  </li>
                ))
              : editableRecipe.instructions.map((step, idx) => (
                  <li key={idx} className="text-gray-800">
                    {step}
                  </li>
                ))}
          </ol>
        </div>
      ) : (
        <p className="text-gray-600">No instructions provided.</p>
      )}

      {/* Action Buttons */}
      <div className="actions flex flex-wrap gap-4 mt-4">
        {/* Edit / Cancel Toggle */}
        {!editing ? (
          <ActionButton
            label="Edit"
            onClick={() => setEditing(true)}
            variant="secondary"
            size="md"
          />
        ) : (
          <ActionButton
            label="Cancel"
            onClick={() => {
              // Revert to the original (decoded) recipe
              setEditableRecipe(decodedRecipe);
              setEditing(false);
            }}
            variant="danger"
            size="md"
          />
        )}

        <ActionButton
          label="Print"
          onClick={handlePrint}
          variant="success"
          size="md"
        />

        <ActionButton
          label="Save to Recipe Box"
          onClick={handleSave}
          variant="primary"
          size="md"
        />
      </div>
      {/* ----------- Toast ----------- */}
      <Toast
        message={toast.message}
        type={toast.type}
        onClose={() => setToast({ message: "", type: "" })}
      />
    </div>
  );
}

RecipeResult.propTypes = {
  recipe: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    ingredients: PropTypes.arrayOf(PropTypes.string),
    instructions: PropTypes.arrayOf(PropTypes.string),
  }),
  user: PropTypes.object,
  onReset: PropTypes.func.isRequired,
};

export default RecipeResult;
