import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ActionButton from "../components/Buttons/ActionButton";

// Import the helper functions
import { authenticateUser, updateUser } from "../utils/helpers.ts";

function Account({ user, setUser }) {
  const [authMode, setAuthMode] = useState(user ? "loggedIn" : "login");
  const [formData, setFormData] = useState({ email: "", password: "", username: "" });
  const [updateData, setUpdateData] = useState({
    username: "",
    currentPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setUpdateData({
        username: user.username,
        currentPassword: "",
        newPassword: "",
      });
    }
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    setAuthMode("login");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  };

  // ----- Moved fetch logic to accountHelpers -----
  const useHandleAuthSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await authenticateUser(formData, authMode);

      if (data.error) {
        setError(data.error);
      } else {
        localStorage.setItem("user", JSON.stringify(data));
        setUser(data);
        setError("");
        setAuthMode("loggedIn");
        navigate("/"); // Navigate to the root path on success
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  };

  // ----- Moved fetch logic to accountHelpers -----
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!user) return;

    try {
      const data = await updateUser(user.id, updateData);

      if (data.error) {
        setError(data.error);
        setSuccessMessage("");
      } else {
        setUser(data);
        localStorage.setItem("user", JSON.stringify(data));
        setError("");
        setSuccessMessage("Account details updated successfully!");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  };

  if (authMode === "loggedIn" && user) {
    return (
      <div className="account-page max-w-xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-purple-700 mb-4">
          Welcome, {user.username}!
        </h1>
        <p className="text-gray-700 mb-4">Email: {user.email}</p>
        <p className="text-gray-700 mb-4">
          Subscription: {user.is_paying_member ? "Premium" : "Default"}
        </p>
        <ActionButton
          label="Log Out"
          onClick={handleLogout}
          variant="danger"
          size="md"
          className="mb-4"
        />
        <h2 className="text-xl font-semibold text-purple-600 mb-4">Update Account</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
        <form onSubmit={handleUpdateSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-600">Username:</label>
            <input
              type="text"
              name="username"
              value={updateData.username}
              onChange={handleUpdateInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600">Current Password:</label>
            <input
              type="password"
              name="currentPassword"
              value={updateData.currentPassword}
              onChange={handleUpdateInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600">New Password:</label>
            <input
              type="password"
              name="newPassword"
              value={updateData.newPassword}
              onChange={handleUpdateInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
              required
            />
          </div>
          <ActionButton
            label="Update Account"
            type="submit"
            variant="primary"
            size="md"
          />
        </form>
      </div>
    );
  }

  return (
    <div className="auth-container max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-purple-700 mb-4">
        {authMode === "login" ? "Log In" : "Sign Up"}
      </h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={useHandleAuthSubmit} className="space-y-4">
        {authMode === "signup" && (
          <div>
            <label className="block text-gray-600">Username:</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
              required
            />
          </div>
        )}
        <div>
          <label className="block text-gray-600">Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
            required
          />
        </div>
        <div>
          <label className="block text-gray-600">Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
            required
          />
        </div>
        <ActionButton
          label={authMode === "login" ? "Log In" : "Sign Up"}
          type="submit"
          variant="primary"
          size="md"
        />
      </form>
      <p className="mt-4 text-gray-600">
        {authMode === "login" ? "Don't have an account?" : "Already have an account?"}{" "}
        <button
          onClick={() => setAuthMode(authMode === "login" ? "signup" : "login")}
          className="text-purple-600 hover:underline"
        >
          {authMode === "login" ? "Sign Up" : "Log In"}
        </button>
      </p>
    </div>
  );
}

Account.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    is_paying_member: PropTypes.bool,
  }),
  setUser: PropTypes.func.isRequired,
};

export default Account;
