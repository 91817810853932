// utils/cookies.js

/**
 * Retrieve a cookie value by name
 * @param {string} name
 * @returns {string} cookie value (or empty string if not found)
 */
export function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : '';
  }
  
  /**
   * Set a cookie by name, value, and optional days until expiration
   * @param {string} name
   * @param {string | number} value
   * @param {number} [days=365]
   */
  export function setCookie(name, value, days = 365) {
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
  }
  