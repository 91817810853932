import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ActionButton from "../components/Buttons/ActionButton";
import Modal from "../components/Modals/RecipeModal";
import Toast from "../components/Toast";
import FormModal from "../components/Modals/FormModal";
import EditRecipeModal from "../components/Modals/EditRecipeModal"; // <-- NEW IMPORT

// Import the helper functions
import {
  getRecipes,
  getTabs,
  deleteRecipe,
  addTab,
  deleteTab,
  editRecipe
} from "../utils/helpers.ts"; // adjust path as needed

function RecipeBox({ user }) {
  const [recipes, setRecipes] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("General");
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Toast state
  const [toast, setToast] = useState({ message: "", type: "" });

  // NEW STATES for Add Tab / Delete Tab modals
  const [isAddTabModalOpen, setIsAddTabModalOpen] = useState(false);
  const [isDeleteTabModalOpen, setIsDeleteTabModalOpen] = useState(false);
  const [deleteTabCandidate, setDeleteTabCandidate] = useState(null);

  // -------------- NEW STATE for the Edit Modal --------------
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Helper to show toasts
  const showToast = (message, type = "info") => {
    setToast({ message, type });
    setTimeout(() => {
      setToast({ message: "", type: "" });
    }, 3000);
  };

  useEffect(() => {
    if (user) {
      fetchUserRecipes();
      fetchUserTabs();
    }
    // eslint-disable-next-line
  }, [user]);

  const fetchUserRecipes = async () => {
    try {
      const data = await getRecipes(user.id);
      // Sort recipes by name (optional)
      setRecipes(data.sort((a, b) => a.recipe_name.localeCompare(b.recipe_name)));
    } catch (error) {
      console.error("Error fetching recipes:", error);
      showToast("Failed to fetch recipes.", "error");
    }
  };

  const fetchUserTabs = async () => {
    try {
      const data = await getTabs(user.id);
      setTabs(data);
    } catch (error) {
      console.error("Error fetching tabs:", error);
      showToast("Failed to fetch tabs.", "error");
    }
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  // Delete a recipe
  const handleDeleteRecipe = async (recipeId) => {
    try {
      await deleteRecipe(user.id, recipeId);
      setRecipes((prev) => prev.filter((recipe) => recipe.id !== recipeId));
      showToast("Recipe deleted successfully!", "success");
    } catch (error) {
      console.error("Error deleting recipe:", error);
      showToast("Failed to delete recipe.", "error");
    }
  };

  // Show Add Tab Modal
  const handleShowAddTabModal = () => {
    setIsAddTabModalOpen(true);
  };

  // Add new tab
  const handleAddTabSubmit = async (formData) => {
    const tabName = formData.tabName.trim();
    if (!tabName) {
      showToast("Tab name cannot be empty.", "warning");
      return;
    }
    if (tabs.includes(tabName)) {
      showToast("Tab name already exists.", "warning");
      return;
    }

    try {
      await addTab(user.id, tabName);
      setTabs((prev) => [...prev, tabName]);
      showToast("Tab added successfully!", "success");
    } catch (error) {
      showToast("Failed to add tab.", "error");
    }

    setIsAddTabModalOpen(false);
  };

  // Show Delete Tab Modal
  const handleShowDeleteTabModal = (tab) => {
    // Guard for "General" tab
    if (tab === "General") {
      showToast('The "General" tab cannot be deleted.', "warning");
      return;
    }
    setDeleteTabCandidate(tab);
    setIsDeleteTabModalOpen(true);
  };

  // Confirm Delete Tab
  const handleDeleteTabConfirm = async () => {
    const tab = deleteTabCandidate;
    if (!tab) {
      setIsDeleteTabModalOpen(false);
      return;
    }

    try {
      await deleteTab(user.id, tab);
      setTabs((prev) => prev.filter((t) => t !== tab));
      setSelectedTab("General");
      showToast(`Tab "${tab}" deleted successfully!`, "success");
    } catch (error) {
      showToast("Failed to delete tab.", "error");
    } finally {
      setIsDeleteTabModalOpen(false);
      setDeleteTabCandidate(null);
    }
  };

  // -------------- NEW: Handle recipe updates from Edit modal --------------
  const handleSaveEditedRecipe = async (updatedRecipe) => {
    try {
      // Make sure to adapt these fields to match your backend payload structure
      const response = await editRecipe(
        user.id,
        updatedRecipe.id, 
        {
          // The helper expects a "name", so we pass updatedRecipe.recipe_name
          name: updatedRecipe.recipe_name,
          ingredients: updatedRecipe.recipe_data.ingredients,
          instructions: updatedRecipe.recipe_data.instructions,
          url: updatedRecipe.url,
        },
        updatedRecipe.tab_name // optionally pass a new tab name if changed
      );
  
      // `response` here is the updated recipe data returned from the backend
      // Now replace the old recipe in state
      setRecipes((prevRecipes) =>
        prevRecipes.map((r) => (r.id === response.id ? response : r))
      );

      fetchUserRecipes();
  
      showToast("Recipe updated successfully!", "success");
    } catch (error) {
      // If the backend responded with an error (or network error)
      console.error("Error updating recipe:", error);
      showToast(error.message || "Failed to update recipe.", "error");
    }
  };

  const filteredRecipes = recipes.filter(
    (recipe) => recipe.tab_name === selectedTab
  );

  // If user not logged in
  if (!user) {
    return (
      <div className="recipe-box max-w-xl mx-auto bg-white p-6 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold text-purple-700 mb-4">
          Your Recipe Box
        </h1>
        <p className="text-gray-600">Please log in to view your saved recipes.</p>
      </div>
    );
  }

  return (
    <div className="recipe-box max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-purple-700 mb-6">Your Recipe Box</h1>
      <div className="recipe-box-container flex flex-col lg:flex-row gap-6">
        {/* Tabs Section */}
        <div className="tabs-section flex-1">
          <h2 className="text-xl font-semibold text-pink-600 mb-4">Tabs</h2>
          <ul className="tabs-list space-y-2">
            {tabs.map((tab) => (
              <li
                key={tab}
                onClick={() => handleTabSelect(tab)}
                className={`tab-item px-4 py-2 rounded-lg cursor-pointer transition ${
                  tab === selectedTab
                    ? "bg-purple-700 text-white"
                    : "bg-pink-100 text-purple-700"
                }`}
              >
                {tab}
              </li>
            ))}
          </ul>
          <ActionButton
            label="+ Add Tab"
            onClick={handleShowAddTabModal}
            variant="success"
            size="sm"
            className="mt-4"
          />
          {selectedTab !== "General" && (
            <ActionButton
              label="Delete Tab"
              onClick={() => handleShowDeleteTabModal(selectedTab)}
              variant="danger"
              size="sm"
              className="mt-2"
            />
          )}
        </div>

        {/* Recipes Section */}
        <div className="recipes-section flex-2">
          <h2 className="text-xl font-semibold text-pink-600 mb-4">
            {selectedTab} Recipes
          </h2>
          {filteredRecipes.length === 0 ? (
            <p className="text-gray-600">No recipes in this tab.</p>
          ) : (
            <div className="recipe-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredRecipes.map((recipe) => (
                <div
                  key={recipe.id}
                  className="recipe-card p-4 bg-pink-100 rounded-lg shadow-md cursor-pointer"
                  onClick={() => {
                    setSelectedRecipe(recipe);
                    setIsModalOpen(true);
                  }}
                >
                  <h3 className="text-lg font-bold text-purple-700">
                    {recipe.recipe_name}
                  </h3>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* ========== 1) RECIPE MODAL (FIRST MODAL) ========== */}
      {isModalOpen && selectedRecipe && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <h2 className="text-xl font-bold text-purple-700 mb-4">
            {selectedRecipe.recipe_name}
          </h2>
          <h4 className="text-lg font-bold text-purple-700 mb-4">
            {selectedRecipe.url}
          </h4>
          <h3 className="text-lg font-semibold text-pink-600 mb-2">
            Ingredients
          </h3>
          <ul className="list-disc list-inside text-gray-800 mb-4">
            {selectedRecipe.recipe_data.ingredients.map((ing, idx) => (
              <li key={idx}>{ing}</li>
            ))}
          </ul>
          <h3 className="text-lg font-semibold text-pink-600 mb-2">
            Instructions
          </h3>
          <ol className="list-decimal list-inside text-gray-800">
            {selectedRecipe.recipe_data.instructions.map((step, idx) => (
              <li key={idx}>{step}</li>
            ))}
          </ol>

          <div className="mt-6 flex gap-4">
            {/* Edit Button - closes first modal, opens second */}
            <ActionButton
              label="Edit"
              onClick={() => {
                setIsModalOpen(false);
                setIsEditModalOpen(true);
              }}
              variant="primary"
              size="md"
            />

            {/* Delete Recipe Button */}
            <ActionButton
              label="Delete Recipe"
              onClick={() => {
                handleDeleteRecipe(selectedRecipe.id);
                setIsModalOpen(false);
              }}
              variant="danger"
              size="md"
            />
          </div>
        </Modal>
      )}

      {/* ========== 2) EDIT RECIPE MODAL (SECOND MODAL) ========== */}
      {isEditModalOpen && selectedRecipe && (
        <EditRecipeModal
          recipe={selectedRecipe}
          onSave={(updatedRecipe) => {
            // Save the changes in local state
            handleSaveEditedRecipe(updatedRecipe);

            // Close Edit modal & re-open the Recipe modal (to show updated data)
            setIsEditModalOpen(false);
            setIsModalOpen(true);
          }}
          onClose={() => {
            // Close Edit modal & re-open the Recipe modal (no changes saved)
            setIsEditModalOpen(false);
            setIsModalOpen(true);
          }}
        />
      )}

      {/* ----------- Toast ----------- */}
      <Toast
        message={toast.message}
        type={toast.type}
        onClose={() => setToast({ message: "", type: "" })}
      />

      {/* ----------- "Add Tab" Modal ----------- */}
      {isAddTabModalOpen && (
        <FormModal
          title="Add a New Tab"
          fields={[
            {
              name: "tabName",
              label: "Tab Name",
              placeholder: "Enter a new tab name",
            },
          ]}
          onSubmit={handleAddTabSubmit}
          onClose={() => setIsAddTabModalOpen(false)}
        />
      )}

      {/* ----------- "Delete Tab" Confirm Modal (no fields, just a message) ----------- */}
      {isDeleteTabModalOpen && (
        <FormModal
          title="Delete Tab"
          message={`Are you sure you want to delete the tab "${deleteTabCandidate}"?`}
          fields={[]} // no text inputs, just a confirm
          onSubmit={() => handleDeleteTabConfirm()}
          onClose={() => {
            setIsDeleteTabModalOpen(false);
            setDeleteTabCandidate(null);
          }}
        />
      )}
    </div>
  );
}

RecipeBox.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default RecipeBox;
