import React from "react";
import PropTypes from "prop-types";

function RecipeModal({ children, onClose, onEdit }) {
  // Close the modal if user taps/clicks on the overlay
  const handleOverlayClick = () => {
    onClose();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOverlayClick}
    >
      {/* Prevent clicks inside the modal from closing it */}
      <div
        className="
          bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative
          max-h-[80vh] overflow-y-auto
        "
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Close"
        >
          ✖
        </button>

        {/* Modal Content */}
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}

RecipeModal.propTypes = {
  children: PropTypes.node.isRequired, // Content to render inside the modal
  onClose: PropTypes.func.isRequired,  // Function to close this modal
  onEdit: PropTypes.func.isRequired,   // Function to open the Edit modal
};

export default RecipeModal;
