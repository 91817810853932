// src/components/Toast.jsx

import React from "react";
import PropTypes from "prop-types";

/**
 * Toast Component
 * Slides in from the right at the bottom of the screen
 */
function Toast({ message, type, onClose }) {
  if (!message) return null; // If there's no message, don’t render.

  // Background color by toast type
  let bgColor;
  switch (type) {
    case "success":
      bgColor = "bg-green-500";
      break;
    case "error":
      bgColor = "bg-red-500";
      break;
    case "warning":
      bgColor = "bg-yellow-500";
      break;
    case "info":
    default:
      bgColor = "bg-blue-500";
  }

  return (
    <div
      className={`
        fixed bottom-5 right-5 
        transform transition-transform duration-300 ease-out
        p-4 rounded shadow-lg text-white
        flex items-center justify-between space-x-4 z-50
        ${bgColor}
        translate-x-0
      `}
    >
      <span>{message}</span>
      <button
        onClick={onClose}
        className="text-xl font-semibold opacity-80 hover:opacity-100"
        aria-label="Close Toast"
      >
        ✕
      </button>
    </div>
  );
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
  onClose: PropTypes.func.isRequired,
};

export default Toast;
