import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * FormModal
 * A flexible modal for text inputs or confirmation.
 */
function FormModal({ title, fields, message, onSubmit, onClose }) {
  // Build initial form state for each field
  const initialValues = fields.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {});
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); 
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Clicking outside => close modal */}
      <div
        className="absolute inset-0"
        onClick={onClose}
        aria-hidden="true"
      />

      {/* Modal Content */}
      <div
        className="
          relative bg-white p-6 rounded-md shadow-md
          max-w-md w-full
        "
        onClick={(e) => e.stopPropagation()} // prevent close on inside click
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close Modal"
        >
          ✕
        </button>

        {/* Title */}
        <h2 className="text-xl font-semibold mb-4">{title}</h2>

        {/* If there's a message (e.g., confirm text) */}
        {message && <p className="mb-4 text-gray-700">{message}</p>}

        <form onSubmit={handleFormSubmit} className="space-y-4">
          {/* Render inputs if any fields were passed */}
          {fields.map((field) => (
            <div key={field.name}>
              <label
                htmlFor={field.name}
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                id={field.name}
                placeholder={field.placeholder || ""}
                value={formData[field.name] || ""}
                onChange={handleChange}
                className="
                  w-full p-2 border border-gray-300
                  rounded focus:outline-none focus:ring-1
                  focus:ring-blue-500
                "
              />
            </div>
          ))}

          <div className="flex justify-end space-x-4 mt-4">
            {/* Cancel Button */}
            <button
              type="button"
              onClick={onClose}
              className="
                px-4 py-2 bg-gray-200 text-gray-700 
                rounded hover:bg-gray-300
              "
            >
              Cancel
            </button>

            {/* Submit Button */}
            <button
              type="submit"
              className="
                px-4 py-2 bg-blue-600 text-white
                rounded hover:bg-blue-700
              "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

FormModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

FormModal.defaultProps = {
  title: "Modal Title",
  message: "",
  fields: [],
};

export default FormModal;
