import React from "react";
import PropTypes from "prop-types";

function ActionButton({ label, icon, onClick, variant = "primary", size = "md", disabled = false }) {
  const baseStyles = "inline-flex items-center gap-2 rounded-md font-medium transition duration-300 ease-in-out focus:outline-none";
  const sizeStyles = {
    sm: "text-sm px-2 py-1",
    md: "text-base px-3 py-2",
    lg: "text-lg px-4 py-3",
  };
  const variantStyles = {
    primary: "bg-indigo-300 text-white hover:bg-indigo-400", // Muted indigo for a calm yet distinctive look
    secondary: "bg-slate-400 text-white hover:bg-slate-500", // Neutral slate gray for versatility
    danger: "bg-red-300 text-white hover:bg-red-400", // Softer rose for a less alarming danger state
    success: "bg-emerald-400 text-white hover:bg-emerald-500", // Earthy olive green for a natural, balanced success state
  };  
  
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      {icon && <span className="action-icon">{icon}</span>}
      {label}
    </button>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node, // Accepts React elements like SVG or FontAwesome icons
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary", "danger", "success"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool,
};

export default ActionButton;
