import React from "react";
import PropTypes from "prop-types";

function NewFeaturesModal({ features, name, onClose }) {
  // Clicking outside the modal closes it
  const handleOverlayClick = () => {
    onClose();
  };

  return (
    <div
      className="fixed px-2 inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-white w-full max-w-md p-6 rounded shadow relative"
        onClick={(e) => e.stopPropagation()} // prevent close when clicking inside
      >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={onClose}
          aria-label="Close"
        >
          ✖
        </button>

        <h2 className="text-xl font-bold mb-4">{name}</h2>
        <ul className="list-disc list-inside space-y-2">
          {features.map((feature, idx) => (
            <li key={idx}>{feature}</li>
          ))}
        </ul>

        <div className="mt-6 text-right">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded"
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  );
}

NewFeaturesModal.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewFeaturesModal;
