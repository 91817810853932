import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ActionButton from "../Buttons/ActionButton";

function Hero({ url, setUrl, handleFetchRecipe, error }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form default submission
    const success = await handleFetchRecipe(url);
    if (success) {
      navigate("/results"); // Navigate to the results page
    }
  };

  const sendEmail = () => {
    const recipient = "the.recipe.fox@gmail.com";
    const subject = encodeURIComponent("Sad Problem Found!!!");
    const body = encodeURIComponent("Hello Mr. Dev,\n\nI have an issue to report: ");

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="hero bg-gradient-to-b from-gray-200 to-orange-50 py-16 px-6 text-center border-b border-pink-300">
      <div className="hero-content max-w-4xl mx-auto">
        <img src="/logo.png" alt="Logo" className="hero-logo w-32 mx-auto mb-8" />
        <p className="text-lg text-black mb-6">
          Paste a recipe URL, and we'll extract all the details for you.
        </p>
        <form onSubmit={handleSubmit} className="hero-form flex flex-wrap justify-center gap-3 mb-4">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter recipe URL..."
            className="flex-1 px-4 py-2 border border-pink-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:outline-none"
          />
          <ActionButton
            label="Extract Recipe"
            type="submit"
            variant="primary"
            size="md"
          />
        </form>
        {error && <div className="error-msg text-red-500 mb-4">{error}</div>}
        <ActionButton
          label="Send Feedback or Query"
          onClick={sendEmail}
          variant="secondary"
          size="md"
        />
        <div className="supported-sites mt-6">
          <p className="mb-3 text-purple-600">
            We support a ton of sites, but start here if you don't know where to look!
          </p>
          <li>
            <a
              href="https://www.inspiredtaste.net"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              https://www.inspiredtaste.net
            </a>
          </li>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="toggle-supported mt-3 text-sm text-purple-600 underline focus:outline-none"
          >
            Some sites have known issues; please describe a found issue in feedback
          </button>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {
  url: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  handleFetchRecipe: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default Hero;