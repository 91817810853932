// constants/features.js

export const NEW_FEATURES = {
    id: 4, // Increment this whenever you add new features
    name: "Update 0.1.2",
    features: [
      "---NEW---",
      "Added the ability to edit recipes right after extraction",
      "---PREVIOUS---",
      "Added website links to saved recipes",
      "Added the ability to edit saved recipes",
      "Added new features Modal",
    ]
  };
  