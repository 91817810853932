import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./RecipeModal"; // Reuse your existing `RecipeModal` styling
import ActionButton from "../Buttons/ActionButton";

/**
 * EditRecipeModal
 * @param {object} recipe          The recipe object to be edited
 * @param {function} onClose       Function to close this modal (and optionally reopen the original)
 * @param {function} onSave        Callback to save the updated recipe
 */
function EditRecipeModal({ recipe, onClose, onSave }) {
  // You can adjust these states/fields depending on your recipe schema:
  const [recipeName, setRecipeName] = useState(recipe.recipe_name || "");
  const [url, setUrl] = useState(recipe.url || "");
  const [ingredients, setIngredients] = useState(
    recipe.recipe_data?.ingredients.join("\n") || ""
  );
  const [instructions, setInstructions] = useState(
    recipe.recipe_data?.instructions.join("\n") || ""
  );

  const handleSave = () => {
    const updatedRecipe = {
      ...recipe,
      recipe_name: recipeName,
      url: url,
      recipe_data: {
        ingredients: ingredients
          .split("\n")
          .map((str) => str.trim())
          .filter(Boolean),
        instructions: instructions
          .split("\n")
          .map((str) => str.trim())
          .filter(Boolean),
      },
    };

    onSave(updatedRecipe);
  };

  return (
    <Modal onClose={onClose}>
      <h2 className="text-xl font-bold text-purple-700 mb-4">Edit Recipe</h2>

      {/* Example edit fields */}
      <div className="mb-4">
        <label className="block mb-1 font-semibold text-gray-700">
          Recipe Name
        </label>
        <input
          type="text"
          value={recipeName}
          onChange={(e) => setRecipeName(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-semibold text-gray-700">URL</label>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-semibold text-gray-700">
          Ingredients (seperate sections by pressing enter or return)
        </label>
        <textarea
          rows={4}
          value={ingredients}
          onChange={(e) => setIngredients(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-semibold text-gray-700">
          Instructions (seperate sections by pressing enter or return)
        </label>
        <textarea
          rows={4}
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="flex gap-4">
        <ActionButton label="Cancel" onClick={onClose} variant="secondary" />
        <ActionButton label="Save" onClick={handleSave} variant="success" />
      </div>
    </Modal>
  );
}

EditRecipeModal.propTypes = {
  recipe: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditRecipeModal;
