import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import NavbarLinks from "./NavbarLinks";

function Navbar({ user, onLogout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const links = [
    { label: "Home", path: "/" },
    { label: "Recipe Box", path: "/recipe-box" },
    { label: user ? user.username : "Account", path: "/account" },
  ];

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="navbar bg-[#b77b64] shadow-md px-4 py-3 flex justify-between items-center mb-4">
      {/* Brand/Logo */}
      <div className="flex items-center ml-4 lg:ml-0">
        <NavLink
          to="/"
          className="text-2xl font-title text-black"
          onClick={closeMenu}
        >
          The Recipe Fox
        </NavLink>
      </div>

      {/* Hamburger menu (mobile) */}
      <div className="lg:hidden">
        <HamburgerMenu isOpen={isMenuOpen} onToggle={toggleMenu} />
      </div>

      {/* Desktop navbar links (lg screens) */}
      <div className="hidden lg:flex lg:items-center">
        <NavbarLinks links={links} onLinkClick={closeMenu} />
        {user && (
          <button
            onClick={onLogout}
            className="text-white font-medium hover:text-gray-400 transition ml-4"
          >
            Log Out
          </button>
        )}
      </div>

      {/* Mobile menu (slides up/down) */}
      <div
        className={`
          absolute top-[64px] left-0 right-0 bg-[#fff8ed] shadow-md z-50
          overflow-hidden lg:hidden transform-gpu origin-top
          transition-all duration-300 ease-out
          ${isMenuOpen ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"}
        `}
      >
        <div className="flex flex-col items-center p-4 space-y-4">
          <NavbarLinks links={links} onLinkClick={closeMenu} />
          {user && (
            <button
              onClick={onLogout}
              className="text-red-500 font-medium hover:text-red-600 transition"
            >
              Log Out
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }),
  onLogout: PropTypes.func.isRequired,
};

export default Navbar;


