import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Home/Hero";
import RecipeResult from "./components/RecipeResults/RecipeResult";
import RecipeBox from "./pages/RecipeBox";
import Account from "./pages/Account";

// 1) Import the helper
import { fetchRecipeFromUrl } from "./utils/helpers.ts";

// 2) Import the new modal and constants
import NewFeaturesModal from "./components/Modals/NewFeaturesModal";
import { NEW_FEATURES } from "./constants/features.ts";
import { getCookie, setCookie } from "./utils/cookies.js";

function App() {
  const [url, setUrl] = useState("");
  const [recipe, setRecipe] = useState(null);
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);

  // ========== New Feature Modal state ==========
  const [showNewFeaturesModal, setShowNewFeaturesModal] = useState(false);

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  // 3) Check cookie for new features
  useEffect(() => {
    const storedId = parseInt(getCookie("newFeaturesId"), 10);
    if (storedId !== NEW_FEATURES.id) {
      // Show the modal if cookie doesn't match the latest ID
      setShowNewFeaturesModal(true);
    }
  }, []); // Only run once on initial load

  // 4) On modal close, store the current features ID in a cookie
  const handleCloseFeaturesModal = () => {
    setCookie("newFeaturesId", NEW_FEATURES.id);
    setShowNewFeaturesModal(false);
  };

  // Replaces your inline fetch logic with the helper
  const handleFetchRecipe = async (recipeUrl) => {
    setRecipe(null);
    setError("");

    try {
      const fetchedData = await fetchRecipeFromUrl(recipeUrl);
      setRecipe(fetchedData);
      return { success: true };
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to fetch recipe.");
      return { success: false };
    }
  };

  const handleReset = () => {
    setUrl("");
    setRecipe(null);
    setError("");
  };

  return (
    <Router>
      <div className="app flex flex-col min-h-screen bg-gray-200">
        <Navbar user={user} onLogout={() => setUser(null)} />
        <main className="flex-grow">
          <Routes>
            {/* Home Page */}
            <Route
              path="/"
              element={
                <Hero
                  url={url}
                  setUrl={setUrl}
                  error={error}
                  handleFetchRecipe={handleFetchRecipe}
                />
              }
            />

            {/* Recipe Results Page */}
            <Route
              path="/results"
              element={
                recipe ? (
                  <RecipeResult
                    recipe={recipe}
                    user={user}
                    onReset={handleReset}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            {/* Recipe Box Page */}
            <Route path="/recipe-box" element={<RecipeBox user={user} />} />

            {/* Account Page */}
            <Route
              path="/account"
              element={<Account user={user} setUser={setUser} />}
            />

            {/* Fallback Route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>

        {/* NEW FEATURES MODAL (conditionally render it) */}
        {showNewFeaturesModal && (
          <NewFeaturesModal
            features={NEW_FEATURES.features}
            name={NEW_FEATURES.name}
            onClose={handleCloseFeaturesModal}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
