import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function NavbarLinks({ links, onLinkClick }) {
  return (
    <ul className="flex flex-col lg:flex-row gap-4 lg:gap-6 items-center">
      {links.map((link) => (
        <li key={link.path}>
          <NavLink
            to={link.path}
            className={({ isActive }) =>
              `nav-link text-gray-800 hover:text-blue-600 transition ${
                isActive ? "font-bold text-blue-600" : ""
              }`
            }
            onClick={onLinkClick}
          >
            {link.label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

NavbarLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired, // Display text
      path: PropTypes.string.isRequired,  // URL path
    })
  ).isRequired,
  onLinkClick: PropTypes.func, // Callback for when a link is clicked
};

export default NavbarLinks;
