// constants

// const API_BASE_URL = "http://192.168.153.197:3000";
const API_BASE_URL = "https://recipe-extractor-backend.onrender.com/api";

/**
 * Fetch all recipes for a given user.
 */
export async function getRecipes(userId: string) {
  const response = await fetch(`${API_BASE_URL}/users/${userId}/saved-recipes`);
  if (!response.ok) {
    throw new Error("Error fetching recipes");
  }
  const data = await response.json();
  return data;
}

/**
 * Fetch all tabs for a given user.
 */
export async function getTabs(userId: string) {
  const response = await fetch(`${API_BASE_URL}/users/${userId}/tabs`);
  if (!response.ok) {
    throw new Error("Error fetching tabs");
  }
  const data = await response.json();
  return data;
}

/**
 * Delete a specific recipe by ID.
 */
export async function deleteRecipe(userId: string, recipeId: string) {
  const response = await fetch(
    `${API_BASE_URL}/users/${userId}/saved-recipes/${recipeId}`,
    {
      method: "DELETE",
    }
  );

  if (!response.ok) {
    throw new Error("Error deleting recipe");
  }
}

/**
 * Add a new tab for a given user.
 */
export async function addTab(userId: string, tabName: string) {
  const response = await fetch(`${API_BASE_URL}/users/${userId}/tabs`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ tab_name: tabName }),
  });

  if (!response.ok) {
    throw new Error("Error adding tab");
  }
  return response.json();
}

/**
 * Delete a tab for a given user.
 */
export async function deleteTab(userId: string, tabName: string) {
  const response = await fetch(
    `${API_BASE_URL}/users/${userId}/tabs/${encodeURIComponent(tabName)}`,
    {
      method: "DELETE",
    }
  );

  if (!response.ok) {
    throw new Error("Error deleting tab");
  }
}

export async function authenticateUser(
  formData: { email: string; password: string; username?: string },
  authMode: "login" | "signup"
) {
  const endpoint = authMode === "login" ? "/api/login" : "/api/signup";
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    throw new Error("Failed to authenticate");
  }

  return response.json();
}

/**
 * Updates the user's account info (username, password).
 * @param userId User's ID
 * @param updateData { username, currentPassword, newPassword }
 */
export async function updateUser(
  userId: string,
  updateData: {
    username: string;
    currentPassword: string;
    newPassword: string;
  }
) {
  const response = await fetch(`${API_BASE_URL}/api/users/${userId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updateData),
  });

  if (!response.ok) {
    throw new Error("Failed to update account");
  }

  return response.json();
}

export async function saveRecipe(userId, recipe, tabName = "General") {
  const response = await fetch(`${API_BASE_URL}/users/${userId}/saved-recipes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      recipe_name: recipe.name,
      recipe_data: {
        ingredients: recipe.ingredients,
        instructions: recipe.instructions,
      },
      tab_name: tabName,
      url:recipe.url
    }),
  });

  if (!response.ok) {
    // Attempt to parse the error from the response
    const errorData = await response.json().catch(() => ({}));
    const message = errorData.error || "Failed to save recipe.";
    throw new Error(message);
  }

  return response.json();
}

export interface UpdatedRecipe {
  name?: string;
  ingredients?: string[];
  instructions?: string[];
  url?: string;
}

// Example interface for the recipe object returned from the server
export interface Recipe {
  id: number;
  recipe_name: string;
  recipe_data: {
    ingredients: string[];
    instructions: string[];
  };
  url?: string;
  tab_name: string;
  created_at: string;
  updated_at: string;
}

export async function editRecipe(
  userId: number,
  recipeId: number,
  updatedRecipe: UpdatedRecipe,
  tabName?: string
): Promise<Recipe> {
  // Build a request body with only the fields that need updating
  // Adjust this structure to match your backend's expected payload
  const requestBody: Record<string, any> = {};

  // If the updated recipe has a new name
  if (updatedRecipe.name) {
    requestBody.recipe_name = updatedRecipe.name;
  }

  // If ingredients or instructions need updating
  if (updatedRecipe.ingredients || updatedRecipe.instructions) {
    requestBody.recipe_data = {
      ingredients: updatedRecipe.ingredients ?? [],
      instructions: updatedRecipe.instructions ?? [],
    };
  }

  // If a new tab name is provided
  if (typeof tabName === "string") {
    requestBody.tab_name = tabName;
  }

  // If a new URL is provided
  if (updatedRecipe.url) {
    requestBody.url = updatedRecipe.url;
  }

  const response = await fetch(
    `${API_BASE_URL}/users/${userId}/saved-recipes/${recipeId}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    // Attempt to parse the error from the response
    const errorData = await response.json().catch(() => ({}));
    const message = errorData.error || "Failed to update recipe.";
    throw new Error(message);
  }

  // Return the updated recipe record
  const updatedRecipeData = (await response.json()) as Recipe;
  return updatedRecipeData;
}

export async function fetchRecipeFromUrl(recipeUrl) {
  try {
    const response = await fetch(
      `https://recipe-extractor-backend.onrender.com/extract-recipe?url=${encodeURIComponent(
        recipeUrl
      )}`
    );
    const data = await response.json();

    // If the response status is not OK (e.g., 4xx or 5xx)
    if (!response.ok) {
      const errorMsg = data.error || "Failed to fetch recipe.";
      throw new Error(errorMsg);
    }

    // Append the original URL to the data before returning
    return {
      ...data,
      url: recipeUrl,
    };
  } catch (err) {
    // Re-throw so the calling function can handle it
    console.error(err);
    throw err;
  }
}

